import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import renderHTML from 'react-render-html'
import Img from 'react-cool-img'
import loadingImage from '../loading.gif'
import './styles.scss'

export class ProjectColumn extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      isExpanding: false,
      isExpanded: false,
      isMobile: window.innerWidth < 768,
      activeImage: 0,
      imageCycler: null,
    }
    this.myDivToFocus = React.createRef()
    this.expandProject = this.expandProject.bind(this)
    this.shrinkProject = this.shrinkProject.bind(this)
    this.nextImage = this.nextImage.bind(this)
    this.prevImage = this.prevImage.bind(this)
    this.cycleImages = this.cycleImages.bind(this)
    this.startImageCycle = this.startImageCycle.bind(this)
    this.stopImageCycle = this.stopImageCycle.bind(this)
  }

  expandProject(e) {
    e.preventDefault()
    console.log('expandProject')
    this.props.checkOrder(this.props.id, this.props.index)
    this.stopImageCycle()
    this.setState(
      {
        isExpanding: true,
      },
      () => {
        //wait until scaling (0.25s css animation) is and set ti isExpanded
        setTimeout(
          function () {
            this.setState({ isExpanded: true })
            this.props.checkPlacement()
          }.bind(this),
          250
        )

        /* NOT NEEDED ANY MORE?
        // start scrollinf immediately to anchor of clicked project
        if (this.myDivToFocus.current && this.state.isMobile) {
          this.myDivToFocus.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            alignToTop: false
          })
        }
        //wait until scaling (0.25s css animation) is done and
        // SCROLL AGAIN (after the project might have been pushed down)
        setTimeout(
          function() {
            //now scroll to anchor of clicked project
            if (this.myDivToFocus.current && this.state.isMobile) {
              this.myDivToFocus.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                alignToTop: false
              })
            }
          }.bind(this),
          250
        )
        */
      }
    )
  }

  shrinkProject(e) {
    e.preventDefault()
    console.log('shrinkProject')
    this.stopImageCycle()
    this.setState({ isExpanded: false })
    this.setState({ isExpanding: false })
  }

  nextImage(e) {
    e.preventDefault()
    //console.log('next image')
    if (this.state.isExpanded) {
      if (this.state.activeImage < this.props.fotos.length - 1) {
        this.setState({ activeImage: this.state.activeImage + 1 })
      } else {
        this.setState({ activeImage: 0 })
      }
    }
  }

  prevImage(e) {
    e.preventDefault()
    if (this.state.isExpanded) {
      if (this.state.activeImage > 0) {
        this.setState({ activeImage: this.state.activeImage - 1 })
      } else {
        this.setState({ activeImage: this.props.fotos.length - 1 })
      }
    }
  }

  startImageCycle() {
    //console.log('startImageCycle: ', this.props.fotos)
    //start auotmatic image cycle
    var intervalId = setInterval(this.cycleImages, 750)
    this.setState({ imageCycler: intervalId })
  }

  cycleImages() {
    //console.log('cycleImages:', this.state.activeImage)
    if (this.state.activeImage < this.props.fotos.length - 1) {
      this.setState({ activeImage: this.state.activeImage + 1 })
    } else {
      this.setState({ activeImage: 0 })
    }
  }

  stopImageCycle() {
    clearInterval(this.state.imageCycler)
    //this.setState({ activeImage: 0 });
  }

  doNothing(e) {
    // e.preventDefault()
  }

  render() {
    let projectID = 'project-' + this.props.id
    let columnWidth = null
    if (this.props.highlight === 'ja') {
      columnWidth = 10
      //console.log('highlight expand to 10')
    }
    if (this.state.isExpanding) {
      if (this.state.isMobile === true) {
        columnWidth = 10
        //console.log('mobile expand to 10')
      } else {
        columnWidth = 16
        //console.log('non mobile expand to 16')
      }
    }

    let images = this.props.fotos.map((el, i) => {
      //console.log('el.foto:', el.foto)
      if (el.foto.url) {
        return (
          el.foto.url && (
            <Img
              placeholder={
                this.state.isExpanded ? el.foto.sizes.medium : loadingImage
              }
              src={
                this.state.isExpanded
                  ? el.foto.sizes.large
                  : el.foto.sizes['project-thumb']
              }
              id={i}
              key={i}
              style={{
                zIndex: this.state.activeImage === i ? '99' : '-1',
                position:
                  this.state.activeImage === i ? 'relative' : 'absolute',
                opacity: this.state.activeImage === i ? '1' : '0',
              }}
              alt={this.props.title}
              className="ui fluid image"
            />
          )
        )
      }
    })

    //console.log('this.props.id:', this.props.id)
    //console.log('prID:', this.props.projectToGrow.prID)

    return (
      <Grid.Column
        key={this.props.id}
        id={'pr-' + this.props.id}
        data-id={this.props.id}
        width={columnWidth}
        className={
          this.props.projectToGrow.prID === this.props.id &&
          this.props.projectToGrow.columnRange === 1
            ? 'project-wrapper grow-one-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === '2a'
            ? 'project-wrapper grow-two-a-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === '2b'
            ? 'project-wrapper grow-two-b-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === 'middle'
            ? 'project-wrapper grow-middle-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === 0.5
            ? 'project-wrapper grow-bigger-column'
            : 'project-wrapper'
        }
      >
        <a
          href={'#'}
          onClick={!this.state.isExpanded ? this.expandProject : this.doNothing}
          title={'Details zu ' + this.props.title}
          className="plain-link"
        >
          <div
            className={
              this.state.isExpanded
                ? 'project expanded'
                : this.state.isExpanding
                ? 'project expanding'
                : 'project'
            }
            id={projectID}
            ref={this.myDivToFocus}
          >
            <Segment
              onMouseEnter={
                !this.state.isExpanded ? this.startImageCycle : null
              }
              onMouseLeave={!this.state.isExpanded ? this.stopImageCycle : null}
              style={{ cursor: !this.state.isExpanded ? 'pointer' : 'auto' }}
            >
              <div className="image-wrapper" onClick={this.nextImage}>
                {images}
              </div>
              {this.state.isExpanded && (
                <div className="arrows-wrapper">
                  <div className="prev arrows" onClick={this.prevImage}>
                    &lt;&lt;
                  </div>
                  <div className="image-counter">
                    {this.state.activeImage + 1} / {this.props.fotos.length}
                  </div>
                  <div className="next arrows" onClick={this.nextImage}>
                    &gt;&gt;
                  </div>
                </div>
              )}
              <h3>{this.props.title}</h3>
              <div className="content">
                {this.state.isExpanded
                  ? renderHTML(this.props.content)
                  : renderHTML(this.props.teaser)}
              </div>
              {this.state.isExpanded && (
                <a
                  href={'#'}
                  onClick={this.shrinkProject}
                  title={this.props.title + 'schliessen'}
                  className="plain-link"
                >
                  <img
                    src={require('../images/closer.svg')}
                    className="closer"
                  />
                </a>
              )}
            </Segment>
          </div>
        </a>
      </Grid.Column>
    )
  }
}
