import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { PageColumn } from './pageColumn'
import { ProjectColumn } from './projectColumn'
import renderHTML from 'react-render-html'
import './App.scss'

const items = []
for (let i = 0; i < 1000; i++) {
  items.push({ id: i })
}

class App extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      pages: [],
      pageDataRoute:
        'https://www.auerconte.com/projekte/wp-json/wp/v2/pages?_embed',
      dataRoute:
        'https://www.auerconte.com/projekte/wp-json/wp/v2/project/?orderby=menu_order&order=asc&per_page=100',
      //'http://auerconte.mathiaslutz.com/wp-json/wp/v2/project/?per_page=100',
      // 'http://www.auerconte.com/wp-json/wp/v2/project/?per_page=100/',
      // 'http://www.auerconte.com/wp-json/',
      projekte: [],
      isMobile: window.innerWidth < 768,
      projectToGrow: {
        prID: -1,
        columnRange: -1,
      },
    }
    this.checkOrder = this.checkOrder.bind(this)
    this.checkPlacement = this.checkPlacement.bind(this)
  }

  /*
  getPages = () =>
    this.state.pages.map((el, i) => (
      <PageColumn
        id={el.id}
        noExpand={el.id === 23 ? true : false}
        index={i}
        key={el.id}
        foto={el['_embedded']['wp:featuredmedia'][0]['media_details']}
        title={el.title.rendered}
        teaser={el.id === 23 ? el.acf.text_info : el.acf.teaser}
        content={el.content.rendered}
        checkOrder={this.checkOrder}
        projectToGrow={this.state.projectToGrow}
        checkPlacement={this.checkPlacement}
      />
    ))
  */

  getProjekte = () =>
    this.state.projekte.map(function (el, i, arr) {
      console.log('el', el.type)

      return el.type === 'page' ? (
        <PageColumn
          id={el.id}
          noExpand={el.id === 23 ? true : false}
          index={i}
          key={el.id}
          foto={el['_embedded']['wp:featuredmedia'][0]['media_details']}
          title={el.title.rendered}
          teaser={el.id === 23 ? el.acf.text_info : el.acf.teaser}
          content={el.content.rendered}
          checkOrder={this.checkOrder}
          projectToGrow={this.state.projectToGrow}
          checkPlacement={this.checkPlacement}
        />
      ) : (
        <ProjectColumn
          id={el.id}
          index={i}
          key={el.id}
          highlight={el.acf.highlight}
          fotos={el.acf.fotos}
          title={el.title.rendered}
          teaser={el.acf.teaser}
          content={el.content.rendered}
          checkOrder={this.checkOrder}
          projectToGrow={this.state.projectToGrow}
          checkPlacement={this.checkPlacement}
        />
      )
    }, this)

  checkPlacement() {
    if (this.state.isMobile) {
      console.log('>>> check placement <<<')
      let wrapperDivs = document.querySelectorAll('.project-wrapper')
      let wrapperArr = Array.prototype.slice.call(wrapperDivs)
      //console.log('wrapperArr:', wrapperArr)
      wrapperArr.map(function (el, i, arr) {
        //console.log(el.innerText.substring(0, 20))
        el.classList.remove('odd')
        el.classList.remove('even')
        //console.log('offsetLeft', el.offsetLeft)
        //console.log('offsetWidth', el.offsetWidth)
        if (el.offsetLeft === 0 && el.offsetWidth <= window.innerWidth / 2) {
          el.classList.add('odd')
        } else if (el.offsetLeft >= window.innerWidth / 2) {
          el.classList.add('even')
        }
      })
    }
  }

  componentDidMount() {
    /* WP PAGES */
    fetch(this.state.pageDataRoute)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        //console.log('responseData:::::', responseData)
        let threePages = responseData.filter(function (page) {
          return page.id === 23 || page.id === 25 || page.id === 245
        })
        this.setState({ pages: threePages.reverse() }, () =>
          console.log('PAGES:::::', this.state.pages)
        )
      })
    /* WP PROJECTS */
    fetch(this.state.dataRoute)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        let combinedProjects = this.state.pages.concat(responseData)
        this.setState({ projekte: combinedProjects }, () =>
          console.log('PROJEKTE:::::', this.state.projekte)
        )
      })
  }

  componentDidUpdate() {
    this.checkPlacement()
  }

  checkOrder(targetID, targetIndex) {
    console.log('>>> check order <<<')
    console.log('targetID:', targetID)
    console.log('targetIndex:', targetIndex)
    let projectDivs = document.querySelectorAll('.project-wrapper')
    // Convert projects NodeList to an array
    let projectArr = Array.prototype.slice.call(projectDivs)
    console.log('projectArr:', projectArr)

    let targetY = projectArr[targetIndex].offsetTop
    //console.log('targetY:', targetY)

    let projectsInline = projectArr.filter(function (el) {
      return el.offsetTop === targetY
    })
    //console.log('projectsInline:', projectsInline)

    let gridDiv = document.querySelector('.ui.stackable.grid')
    let gridWidth = gridDiv.offsetWidth

    let newIndex = -1
    let oldIndex = -1
    let rowWidth = 0
    projectsInline.map(
      function (el, i, arr) {
        let currID = parseInt(el.dataset.id)
        let prevEl = arr[i - 1]
        let prevID = -1
        if (prevEl) {
          prevID = parseInt(prevEl.dataset.id)
        }
        rowWidth += el.offsetWidth

        if (currID === targetID && i !== 0) {
          // ***************
          // 3 PROJECTS ROW
          // ***************
          if (projectsInline.length === 3) {
            if (i === 1) {
              // EXPAND THE MIDDLE PROJECT
              // MOVE PROJECT 1 IN ARRAY TO 0, ADD PADDING ON BOTH SIDES
              this.setState(
                { projectToGrow: { prID: currID, columnRange: 'middle' } },
                () =>
                  setTimeout(
                    function () {
                      this.setState({
                        projectToGrow: { prID: -1, columnRange: -1 },
                      })
                    }.bind(this),
                    250
                  )
              )
              oldIndex = targetIndex
              newIndex = targetIndex - i
              this.swapProjects(oldIndex, newIndex)
            } else if (i === 2) {
              // EXPAND THE RIGHT PROJECT
              // MOVE PROJECT 2 IN ARRAY TO 0, ADD PADDING ON THE NEW 0 PROJECT
              // LATER MOVE PROJECT 1 IN ARRAY TO 0
              this.setState(
                { projectToGrow: { prID: prevID, columnRange: '2a' } },
                function () {
                  oldIndex = targetIndex - 2
                  newIndex = targetIndex
                  this.moveProject(oldIndex, newIndex)
                  setTimeout(
                    function () {
                      this.setState(
                        {
                          projectToGrow: { prID: currID, columnRange: '2b' },
                        },
                        function () {
                          // LATER MOVE PROJECT 1 IN ARRAY TO 0, ADD PADDING ON THE LEFT
                          oldIndex = targetIndex - 1
                          newIndex = targetIndex - 2
                          this.moveProject(oldIndex, newIndex)
                          setTimeout(
                            function () {
                              this.setState({
                                projectToGrow: { prID: -1, columnRange: -1 },
                              })
                            }.bind(this),
                            125
                          )
                        }
                      )
                    }.bind(this),
                    50
                  )
                }
              )
            }
            // ***************
            // 2 PROJECTS ROW
            // ***************
          } else if (projectsInline.length === 2) {
            if (i == 1 && rowWidth + 10 < gridWidth) {
              // EXPAND THE MIDDLE COLUMN PROJECT OF ONLY TWO
              // MOVE PROJECT 1 IN ARRAY TO 0, ADD PADDING ON BOTH SIDES
              this.setState(
                { projectToGrow: { prID: currID, columnRange: 'middle' } },
                () =>
                  setTimeout(
                    function () {
                      this.setState({
                        projectToGrow: { prID: -1, columnRange: -1 },
                      })
                    }.bind(this),
                    250
                  )
              )
              oldIndex = targetIndex
              newIndex = targetIndex - i
              this.swapProjects(oldIndex, newIndex)
            } else if (prevEl.offsetWidth < el.offsetWidth) {
              // EXPAND THE LEFT SMALL PROJECT OF ONLY TWO
              // MOVE PROJECT 1 IN ARRAY TO 0, ADD PADDING ON THE LEFT
              this.setState(
                { projectToGrow: { prID: currID, columnRange: 0.5 } },
                () =>
                  setTimeout(
                    function () {
                      this.setState({
                        projectToGrow: { prID: -1, columnRange: -1 },
                      })
                    }.bind(this),
                    250
                  )
              )
              oldIndex = targetIndex
              newIndex = targetIndex - i
              this.swapProjects(oldIndex, newIndex)
            } else {
              // EXPAND THE RIGHT PROJECT OF TWO SAME SIZED PROJECTS
              // MOVE PROJECT 1 IN ARRAY TO 0, ADD PADDING THE LEFT
              this.setState(
                { projectToGrow: { prID: currID, columnRange: 1 } },
                () =>
                  setTimeout(
                    function () {
                      this.setState({
                        projectToGrow: { prID: -1, columnRange: -1 },
                      })
                    }.bind(this),
                    250
                  )
              )
              oldIndex = targetIndex
              newIndex = targetIndex - i
              this.swapProjects(oldIndex, newIndex)
            }
          }
        }
      }.bind(this),
      targetID,
      targetIndex
    )
    //console.log('gridWidth:', gridWidth)
    //console.log('rowWidth:', rowWidth)
  }

  swapProjects(oldIndex, newIndex) {
    //console.log('SWAP oldIndex:', oldIndex)
    //console.log('SWAP newIndex:', newIndex)
    //REARRANGE ARRAY
    if (newIndex >= 0 && oldIndex >= 0) {
      this.setState(
        {
          projekte: this.swapIndices(this.state.projekte, newIndex, oldIndex),
        },
        () => console.log('NEW PROJEKT ORDER:', this.state.projekte)
      )
    }
  }

  moveProject(oldIndex, newIndex) {
    console.log('MOVE oldIndex:', oldIndex)
    console.log('MOVE newIndex:', newIndex)

    //REARRANGE ARRAY
    if (newIndex >= 0 && oldIndex >= 0) {
      this.setState(
        {
          projekte: this.moveIndice(this.state.projekte, oldIndex, newIndex),
        },
        () => console.log('NEW PROJEKT ORDER:', this.state.projekte)
      )
    }
  }

  swapIndices = (array, index1, index2) => {
    const newArray = array.slice()
    newArray[index1] = array[index2]
    newArray[index2] = array[index1]
    return newArray
  }

  moveIndice(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  render() {
    return (
      <div className="App">
        <Grid stackable columns={this.state.isMobile ? 2 : 3}>
          {this.getProjekte()}
        </Grid>
      </div>
    )
  }
}

export default App
