import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import renderHTML from 'react-render-html'
import Img from 'react-cool-img'
import loadingImage from '../loading.gif'
import './styles.scss'

export class PageColumn extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      isExpanding: false,
      isExpanded: false,
      isMobile: window.innerWidth < 768,
      activeImage: 0,
    }
    this.myDivToFocus = React.createRef()
    this.expandProject = this.expandProject.bind(this)
    this.shrinkProject = this.shrinkProject.bind(this)
  }

  expandProject(e) {
    e.preventDefault()
    this.props.checkOrder(this.props.id, this.props.index)
    this.setState(
      {
        isExpanding: true,
      },
      () => {
        //wait until scaling (0.25s css animation) is and set ti isExpanded
        setTimeout(
          function () {
            this.setState({ isExpanded: true })
            this.props.checkPlacement()
          }.bind(this),
          250
        )

        /* NOT NEEDED ANY MORE?
        // start scrollinf immediately to anchor of clicked project
        if (this.myDivToFocus.current && this.state.isMobile) {
          this.myDivToFocus.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            alignToTop: false
          })
        }
        //wait until scaling (0.25s css animation) is done and
        // SCROLL AGAIN (after the project might have been pushed down)
        setTimeout(
          function() {
            //now scroll to anchor of clicked project
            if (this.myDivToFocus.current && this.state.isMobile) {
              this.myDivToFocus.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                alignToTop: false
              })
            }
          }.bind(this),
          250
        )
        */
      }
    )
  }

  shrinkProject(e) {
    e.preventDefault()
    console.log('shrinkProject')
    this.setState({ isExpanded: false })
    this.setState({ isExpanding: false })
  }

  doNothing(e) {
    //e.preventDefault()
  }

  render() {
    let projectID = 'page-' + this.props.id
    let columnWidth = null
    if (this.props.highlight === 'ja') {
      columnWidth = 10
      //console.log('highlight expand to 10')
    }
    if (this.state.isExpanding) {
      if (this.state.isMobile === true) {
        columnWidth = 10
        //console.log('mobile expand to 10')
      } else {
        columnWidth = 16
        //console.log('non mobile expand to 16')
      }
    }

    let foto = this.props.foto
    let fotoPostThumb = foto.sizes['post-thumbnail']
    let fotoMediumLarge = foto.sizes.medium_large
    let fotoLarge = foto.sizes.large
    //console.log('foto:', foto)
    //console.log('foto.sizes.medium.source_url:', foto.sizes.medium.source_url)
    //console.log('foto.sizes.large.source_url:', foto.sizes.large.source_url)

    //console.log('this.props.id:', this.props.id)
    //console.log('prID:', this.props.projectToGrow.prID)

    return (
      <Grid.Column
        key={this.props.id}
        id={'pr-' + this.props.id}
        data-id={this.props.id}
        width={columnWidth}
        className={
          this.props.projectToGrow.prID === this.props.id &&
          this.props.projectToGrow.columnRange === 1
            ? 'project-wrapper grow-one-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === '2a'
            ? 'project-wrapper grow-two-a-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === '2b'
            ? 'project-wrapper grow-two-b-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === 'middle'
            ? 'project-wrapper grow-middle-column'
            : this.props.projectToGrow.prID === this.props.id &&
              this.props.projectToGrow.columnRange === 0.5
            ? 'project-wrapper grow-bigger-column'
            : 'project-wrapper'
        }
      >
        <a
          href={'#'}
          onClick={
            this.props.noExpand
              ? this.doNothing
              : !this.state.isExpanded
              ? this.expandProject
              : this.doNothing
          }
          title={'Details zu ' + this.props.title}
          className={
            this.state.isExpanded ? 'plain-link disabled' : 'plain-link'
          }
        >
          <div
            className={
              this.state.isExpanded
                ? 'project expanded no-cycle'
                : this.state.isExpanding
                ? 'project expanding'
                : 'project'
            }
            id={projectID}
            ref={this.myDivToFocus}
          >
            <Segment>
              <div
                className={
                  this.state.isExpanded
                    ? 'image-wrapper no-cycle'
                    : this.props.noExpand
                    ? 'image-wrapper no-cycle'
                    : 'image-wrapper'
                }
              >
                {foto.file && (
                  <Img
                    placeholder={
                      this.state.isExpanded
                        ? fotoPostThumb !== 'undefined'
                          ? fotoPostThumb.source_url
                          : foto.sizes.full.source_url
                        : loadingImage
                    }
                    src={
                      this.state.isExpanded
                        ? fotoLarge !== 'undefined'
                          ? fotoLarge.source_url
                          : foto.sizes.full.source_url
                        : fotoPostThumb
                        ? fotoPostThumb.source_url
                        : foto.sizes.full.source_url
                    }
                    style={{
                      position: 'relative',
                    }}
                    alt={this.props.title}
                    className="ui fluid image"
                  />
                )}
              </div>
              <h3>{this.props.title}</h3>
              <div className="content">
                {this.state.isExpanded
                  ? renderHTML(this.props.content)
                  : renderHTML(this.props.teaser)}
              </div>
              {this.state.isExpanded && (
                <a
                  href={'#'}
                  onClick={this.shrinkProject}
                  title={this.props.title + 'schliessen'}
                  className="plain-link"
                >
                  <img
                    src={require('../images/closer.svg')}
                    className="closer"
                  />
                </a>
              )}
            </Segment>
          </div>
        </a>
      </Grid.Column>
    )
  }
}
